<template>
	<div>
		<PageHeader title="Generate Reports"/>
		<section class="gr-form">
			<div class="container">
				<div class="gr-form__cnt">
					<div class="row">
						<div class="col-lg-4 offset-lg-4 col-md-8 offset-md-2 gr-form__wrap">
							<validation-observer ref="observer">
								<b-form @submit.prevent="checkURlAndGenerateReport()">
									<h2 class="gr-form-title">Get your website SEO Audit</h2>
									<div class="mb-3">
										<ValidationProvider rules="required|url" name="WebsiteUrl" vid="websiteUrl">
											<div slot-scope="{ errors }">
												<b-form-input
														type="text"
														class="mt-3"
														placeholder="Website URL"
														v-model="website"
														:state="errors[0] ? false : null">
												</b-form-input>
												<b-form-invalid-feedback>
													{{ errors[0] }}
												</b-form-invalid-feedback>
											</div>
										</ValidationProvider>
										<button class="btn btn-primary mt-3" type="submit">Create Website SEO Audit
										</button>
									</div>
								</b-form>
							</validation-observer>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>

</template>

<script>
    import PageHeader from "../../../../shared/components/PageHeader";
    import {extend} from 'vee-validate';
    import { setInteractionMode } from 'vee-validate'
    import {generate_report} from "../../../../shared/mixins/genearte-report-mixin";
    setInteractionMode('passive');

    extend('url', {
        validate(value) {
            if (value) {
                //eslint-disable-next-line
                return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%]+$/.test(value);
            }

            return false;
        },
        message: 'This value must be a valid URL',
    });

    export default {
        mixins: [generate_report],
        name: "generate-report",
        components: {PageHeader},
        data() {
            return {
                website: ''
            }

        },
		methods: {
            checkURlAndGenerateReport() {
                this.$refs.observer.validate().then(success => {
                    if (!success) {
                        return;
                    }
                    this.generate_report(this.website)
                })
			}
		}
    }
</script>

<style lang="scss">
	@import "../../../../shared/assets/styles/blocks/generate-reports";
</style>
