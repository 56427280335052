
import {check_url_existence_mixin} from "./check-url-existence-mixin";

export const generate_report = {

    mixins: [check_url_existence_mixin],
    methods: {
        generate_report(website) {
            website = this.correctUrl(website);

            this.$store.dispatch('generateReport/createReport', website)
                .then((response) => {
                    if (response && response.token && response.url) {
                        let url = response.url + `/report?token=${response.token}`;
                        window.open(url, '_blank');
                    }
                }).catch((err) => {
                this.showErrorNotifications(err);
            })
        },
    },
};
